import React, { useState, useEffect } from 'react';
import { 
  Row, 
  Col, 
  Modal, 
  Button, 
  Container, 
  Form, 
  Dropdown 
} from 'react-bootstrap';

import {
  viewportThree,
  viewportFour,
  closeBtn
} from './../../assets/images';


import {
  variables,
  helpers
} from 'utilities';

import * as utilComponents from './../utilities';


/**
 * User Delete Modal
 *  - Renders Modal to allow users to delete any chosen profile
 * @param props 
 * @returns 
 */
export const UserDeleteModal: React.FunctionComponent<any> = (props: any) => {

  const { handleClose, show, handleModalFormSubmit, name } = props;

  const handleConfirmDelete = (event: any) => {
    event.preventDefault();

    handleModalFormSubmit();
  }


  return (
    <>
      <Modal show={show} onHide={handleClose} contentClassName="k-mc-center" tabIndex={-1}>
        <Modal.Header closeButton className="px-2 py-1">
          <Modal.Title>
            Confirm User Deletion
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>Do you really want to delete {name}? <br/> This process cannot be undone. </Modal.Body>
        <Modal.Footer className="px-2 py-1">
          <Button variant="secondary" onClick={handleClose} className="p-1">
            Cancel
          </Button>
          <Button variant="primary" onClick={handleConfirmDelete} className="p-1">
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}



/**
 * Group Delete Modal
 *  - Renders Modal to allow users to delete any chosen profile
 * @param props 
 * @returns 
 */
export const GroupDeleteModal: React.FunctionComponent<any> = (props: any) => {

  const { handleClose, show, handleModalFormSubmit, name } = props;

  const handleConfirmDelete = (event: any) => {
    event.preventDefault();

    handleModalFormSubmit();
  }


  return (
    <>
      <Modal show={show} onHide={handleClose} contentClassName="k-mc-center" tabIndex={-1}>
        <Modal.Header closeButton className="px-2 py-1">
          <Modal.Title>
            Confirm Group Deletion
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>Do you really want to delete {name}? <br/> This process cannot be undone. </Modal.Body>
        <Modal.Footer className="px-2 py-1">
          <Button variant="secondary" onClick={handleClose} className="p-1">
            Cancel
          </Button>
          <Button variant="primary" onClick={handleConfirmDelete} className="p-1">
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}




/**
 * User Add Modal
 *  - Renders Form to allow users to add new generic products
 * @param props 
 * @returns 
 */
export const UserAddModal: React.FunctionComponent<any> = (props: any) => {
  const [modalFormBody, setModalFormBody] = useState<any>({});
  const [isPhoneValid, setPhoneValid] = useState(true);
  const [isDisplayNameValid, setDisplayNameValid] = useState(true);
  const [isNameFieldsValid, setNameFieldsValid] = useState(true);
  const [isRoleValid, setRoleValid] = useState(false);
  const [isEmailValid, setEmailValid] = useState(false);
  const [submitClicked, setSubmitClicked] = useState(false);
  

  const [isFormValid, setFormValid] = useState(false);

  const { handleClose, show, handleModalFormSubmit, submitError } = props;

  
  useEffect(() => {
    setModalFormBody({});
    setNameFieldsValid(true);
    setDisplayNameValid(true);
    setEmailValid(false);
    setPhoneValid(true)
    setRoleValid(false);
  }, [show])

  const handleInputChange = (event: any) => {
    // const nameFields: string[] = ['firstName', 'middleName', 'lastName'];

    if(variables.DEFAULT_FORM_NAME_FIELDS.includes(event.target.name)) {
      // event.target.value = event.target.value.split(" ").join("");
      //event.target.value = event.target.value.replace(' ', '');
      if (variables.DEFAULT_FORM_VALIDATION.USER.NAME.test(event.target.value) || event.target.value === '') {
        setNameFieldsValid(true);
      } else {
        setNameFieldsValid(false);
      }
    }
    
    if(event.target.name === 'email') {
      // event.target.value = event.target.value.split(" ").join("");
      //event.target.value = event.target.value.replace(' ', '');
      if (variables.DEFAULT_FORM_VALIDATION.USER.EMAIL.test(event.target.value)) {
        setEmailValid(true);
      } else {
        setEmailValid(false);
      }
    }

    if(event.target.name === 'displayName') {
      // event.target.value = event.target.value.split(" ").join("");
      //event.target.value = event.target.value.replace(' ', '');
      if (variables.DEFAULT_FORM_VALIDATION.USER.DISPLAY_NAME.test(event.target.value) || event.target.value === '') {
        setDisplayNameValid(true);
      } else {
        setDisplayNameValid(false);
      }
    }

    if(event.target.name === 'phone') {
      // event.target.value = event.target.value.split(" ").join("");
      //event.target.value = event.target.value.replace(' ', '');
      if (variables.DEFAULT_FORM_VALIDATION.USER.PHONE_NUMBER.test(event.target.value) || event.target.value === '') {
        setPhoneValid(true);
      } else {
        setPhoneValid(false);
      }
    }

    if(event.target.name === 'role') {
      if ( event.target.value !== '') {
        setRoleValid(true);
      } else {
        setRoleValid(false);
      }
    }

    setModalFormBody({
      ...modalFormBody,
      [event.target.name]: event.target.value,
    });


    if (isDisplayNameValid && isEmailValid && isRoleValid){
      setFormValid(true)
    } else {
      setFormValid(false)
    }

  }

  const validateForm = () => {
    return
  }

  const handleFormSubmit = async(event: any) => {
    event.preventDefault();
    validateForm();

    if (isDisplayNameValid && isEmailValid && isRoleValid){
      setSubmitClicked(true);

      await handleModalFormSubmit({body: {
        // ...modalFormBody,
        name: {
          last: modalFormBody['lastName'] || '',
          first: modalFormBody['firstName'] || '',
          middle: modalFormBody['middleName'] || '',
        },
        display_name: modalFormBody['displayName'] || '',
        email: modalFormBody['email']?.toLowerCase() || '',
        phone: modalFormBody['phone'] || '',
        profile_photo: {
          avatar: 'default'
        },
        role: modalFormBody['role'] || ''
      }});

      setSubmitClicked(false);

    } else {
      helpers.logToOutput('FORM NOT VALID... Won\'t make it so 7');
    }
  }

  const userobj = {
    'name': {
      'last': 'wick',
      'first': 'john',
      'middle': ''
    },
    'display_name': 'jwick',
    'email': 'me@kuro.black',
    'phone': '',
    'profile_photo': {
      'avatar': 'default'
    },
    'role': 'user'
  }

  return (
    <>
      <Modal show={show} onHide={handleClose} centered contentClassName="k-mc-right" dialogClassName="kuro-modal" tabIndex={-1} className="k-modal k-m-right" >
        <Modal.Header className='p-0 ps-3 pe-3 k-display-block k-menu-grey-two-bg'>
          <Row className='mt-1 k-panel-cent-vert'>
            <Col className='col-10'>
              <Modal.Title as="h5" className='p-1'>Add User</Modal.Title>
            </Col>
            <Col className='col-2 k-center-content-hor-right'>
              <button className='custom-close-button k-pointer' onClick={handleClose} style={{ border: 'none', background: 'transparent', cursor: 'pointer' }}>
                <img className='k-mh-20 k-opac-50pc k-pointer k-mb-02r' src={closeBtn}></img>
              </button>
            </Col>
          </Row>
        </Modal.Header>
        
        <Modal.Body className="k-modalBody">
          <Form onSubmit={handleFormSubmit}>
            <Container className="pe-0">
              <Row className="m-0 mb-3">
                <Col className="col-12 ps-0">
                  <span className='txt-10 color-lightHeader'>eMail</span>
                  <Form.Group className="p-0 " controlId="modalUserAddForm.Email">
                    <Form.Control className="p-0 k-inputText k-inputText15 k-bord-col-trans k-outline-none" type="email" placeholder="Email" name='email'  onChange={handleInputChange}/>
                    <hr className='k-border-top-black mt-0 mb-1'></hr>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="m-0 mb-3">
                <Col className="col-6 ps-0">
                  <span className='txt-10 color-lightHeader'>Full Name</span>
                  <Form.Group className="p-0 " controlId="modalUserAddForm.Firstname">
                    <Form.Control className="p-0 k-inputText k-inputText15 k-bord-col-trans k-outline-none" type="text" placeholder="First Name" name='firstName'  onChange={handleInputChange}/>
                    <hr className='k-border-top-black mt-0 mb-1'></hr>
                  </Form.Group>
                </Col>
                <Col className="col-6 ps-0">
                  <span className='txt-10 color-lightHeader'>Display Name</span>
                  <Form.Group className="p-0 " controlId="modalUserAddForm.DisplayName">
                    <Form.Control className="p-0 k-inputText k-inputText15 k-bord-col-trans k-outline-none" type="text" placeholder="Display Name" name='displayName'  onChange={handleInputChange}/>
                    <hr className='k-border-top-black mt-0 mb-1'></hr>
                  </Form.Group>
                </Col>
              </Row>
              <Row className='m-0'>
                {isNameFieldsValid ?  null: <small className='ps-0' style={{color: 'red'}}>Enter a valid User Name(s), lowercase {'(1 - 25 Alphanumeric only)'}</small>}
                {isDisplayNameValid ?  null: <small className='ps-0' style={{color: 'red'}}>Enter a valid Display Name. {'(1 - 25 Alphanumeric only)'}</small>}
                {isEmailValid ?  null: <small className='ps-0' style={{color: 'red'}}>Enter a valid Email</small>}
              </Row>

              <Row className="m-0">
                <Col className="col-6 ps-0">
                  <span className='txt-10 color-lightHeader'>Role</span>
                  <Form.Group className="p-0 k-bord-col-trans k-outline-none" controlId="modalUserAddForm.Role">
                    <Form.Select aria-label="Select a role" className="k-inputSelect k-inputText15-light k-bord-col-trans k-outline-none ps-0" name='role' defaultValue="" onChange={handleInputChange} >
                      <option disabled value="" >Select Role</option>
                      <option value="user" >User</option>
                      <option value="administrator">Admin</option>
                    </Form.Select>
                    <hr className='k-border-top-black mt-0 mb-1'></hr>
                  </Form.Group>
                </Col>
                <Col className="col-6 p-0 k-center-content-hor-right">
                  <div className="pt-3 k-center-content-hor-right-end">
                    <Button variant="secondary " 
                      type="submit"
                      disabled={!isDisplayNameValid || !isRoleValid || !isEmailValid || submitClicked}
                      size="sm" className="k-btn-submit-col txt-14 k-center-content-hor-right-cen k-h-30">
                      Make it so
                    </Button>
                  </div>
                </Col>
              </Row>
              <Row className='m-0'>
                {isRoleValid ?  null: <small className='ps-0'  style={{color: 'red'}}>Select User's Role</small>}
                {submitError? (<>
                  <small style={{color: 'red'}}>{submitError}</small>
                </>): null}
              </Row>
            </Container>
          </Form>
        </Modal.Body>

      </Modal>
    </>
  );
}

export const UserAddModalBackup: React.FunctionComponent<any> = (props: any) => {
  const [modalFormBody, setModalFormBody] = useState<any>({});
  const [isPhoneValid, setPhoneValid] = useState(true);
  const [isDisplayNameValid, setDisplayNameValid] = useState(true);
  const [isNameFieldsValid, setNameFieldsValid] = useState(true);
  const [isRoleValid, setRoleValid] = useState(false);
  const [isEmailValid, setEmailValid] = useState(false);
  const [submitClicked, setSubmitClicked] = useState(false);
  

  const [isFormValid, setFormValid] = useState(false);

  const { handleClose, show, handleModalFormSubmit, submitError } = props;

  
  useEffect(() => {
    setModalFormBody({});
    setNameFieldsValid(true);
    setDisplayNameValid(true);
    setEmailValid(false);
    setPhoneValid(true)
    setRoleValid(false);
  }, [show])

  const handleInputChange = (event: any) => {
    // const nameFields: string[] = ['firstName', 'middleName', 'lastName'];

    if(variables.DEFAULT_FORM_NAME_FIELDS.includes(event.target.name)) {
      // event.target.value = event.target.value.split(" ").join("");
      event.target.value = event.target.value.replace(' ', '');
      if (variables.DEFAULT_FORM_VALIDATION.USER.NAME.test(event.target.value) || event.target.value === '') {
        setNameFieldsValid(true);
      } else {
        setNameFieldsValid(false);
      }
    }
    
    if(event.target.name === 'email') {
      // event.target.value = event.target.value.split(" ").join("");
      event.target.value = event.target.value.replace(' ', '');
      if (variables.DEFAULT_FORM_VALIDATION.USER.EMAIL.test(event.target.value)) {
        setEmailValid(true);
      } else {
        setEmailValid(false);
      }
    }

    if(event.target.name === 'displayName') {
      // event.target.value = event.target.value.split(" ").join("");
      event.target.value = event.target.value.replace(' ', '');
      if (variables.DEFAULT_FORM_VALIDATION.USER.DISPLAY_NAME.test(event.target.value) || event.target.value === '') {
        setDisplayNameValid(true);
      } else {
        setDisplayNameValid(false);
      }
    }

    if(event.target.name === 'phone') {
      // event.target.value = event.target.value.split(" ").join("");
      event.target.value = event.target.value.replace(' ', '');
      if (variables.DEFAULT_FORM_VALIDATION.USER.PHONE_NUMBER.test(event.target.value) || event.target.value === '') {
        setPhoneValid(true);
      } else {
        setPhoneValid(false);
      }
    }

    if(event.target.name === 'role') {
      if ( event.target.value !== '') {
        setRoleValid(true);
      } else {
        setRoleValid(false);
      }
    }

    setModalFormBody({
      ...modalFormBody,
      [event.target.name]: event.target.value,
    });


    if (isDisplayNameValid && isEmailValid && isRoleValid){
      setFormValid(true)
    } else {
      setFormValid(false)
    }

  }

  const validateForm = () => {
    return
  }

  const handleFormSubmit = async(event: any) => {
    event.preventDefault();
    validateForm();

    if (isDisplayNameValid && isEmailValid && isRoleValid){
      setSubmitClicked(true);

      await handleModalFormSubmit({body: {
        // ...modalFormBody,
        name: {
          last: modalFormBody['lastName'] || '',
          first: modalFormBody['firstName'] || '',
          middle: modalFormBody['middleName'] || '',
        },
        display_name: modalFormBody['displayName'] || '',
        email: modalFormBody['email'] || '',
        phone: modalFormBody['phone'] || '',
        profile_photo: {
          avatar: 'default'
        },
        role: modalFormBody['role'] || ''
      }});

      setSubmitClicked(false);

    } else {
      helpers.logToOutput('FORM NOT VALID... Won\'t make it so 7');
    }
  }

  const userobj = {
    'name': {
      'last': 'wick',
      'first': 'john',
      'middle': ''
    },
    'display_name': 'jwick',
    'email': 'me@kuro.black',
    'phone': '',
    'profile_photo': {
      'avatar': 'default'
    },
    'role': 'user'
  }

  return (
    <>

      <Modal show={show} onHide={handleClose} contentClassName="k-mc-right" dialogClassName="kuro-modal" tabIndex={-1} size="lg" className="k-modal k-m-right" >
        <Form onSubmit={handleFormSubmit}>

          <Row className="k-modalHeader p-0 m-0">
            <img alt="name icon" src={viewportFour} className="k-modalTitleImg p-0 k-ctrlImg"/>
            <Row className="k-center ps-3">
              <Col className="col-2 p-0">
                <img alt="name icon" src={viewportThree} className="k-modalIcon pe-1 pt-2 k-ctrlImg"/>
              </Col>
              <Col className="col-10 ps-3 k-pt-2">
                <Row className="k-headerText">Create User</Row>
                <Row className="k-subHeaderText">Authorise - Create - Capitalise</Row>
              </Col>
            </Row>
          </Row>
          
          <Modal.Body className="k-modalBody">
            <Container className="pe-0">
              
              <Row className="">
                <Col className="col-12 pb-3 k-bb">
                  <Row>
                    <Col className="px-0 col-1">
                      <img alt="name icon" src={viewportFour} className="k-modalIconSM pe-1 k-ctrlImg"/>
                    </Col>
                    <Col className="k-inputText18 col-11 ps-0">
                        Name
                    </Col>
                  </Row>
                  
                  <Row className="k-inputTextLarge">
                    <Col className="px-0 col-1"></Col>
                    <Col className="col-11 ps-0 ">
                      <Form.Group className="p-0 " controlId="modalUserAddForm.Firstname">
                        <Form.Control className="p-0 k-inputText k-inputText15" type="text" placeholder="First Name" name='firstName'  onChange={handleInputChange}/>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row className="k-inputTextLarge">
                    <Col className="px-0 col-1"></Col>
                    <Col className="col-11 ps-0 ">
                      <Form.Group className="p-0 " controlId="modalUserAddForm.Middlename">
                        <Form.Control className="p-0 k-inputText k-inputText15" type="text" placeholder="Middle Name" name='middleName'  onChange={handleInputChange}/>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row className="k-inputTextLarge">
                    <Col className="px-0 col-1"></Col>
                    <Col className="col-11 ps-0 ">
                      <Form.Group className="p-0 " controlId="modalUserAddForm.Lastname">
                        <Form.Control className="p-0 k-inputText k-inputText15" type="text" placeholder="Last Name" name='lastName'  onChange={handleInputChange}/>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    {isNameFieldsValid ?  null: <small style={{color: 'red'}}>Enter a valid User Name(s), lowercase {'(5 - 25 Alphanumeric only)'}</small>}
                  </Row>
                </Col>
              </Row>

              <Row className="">
                <Col className="col-12 pb-3 k-bb">
                  <Row>
                    <Col className="px-0 col-1">
                      <img alt="name icon" src={viewportFour} className="k-modalIconSM pe-1 k-ctrlImg"/>
                    </Col>
                    <Col className="k-inputText18 col-11 ps-0">
                        Display name and Contact
                    </Col>
                  </Row>
                  
                  <Row className="k-inputTextLarge">
                    <Col className="px-0 col-1"></Col>
                    <Col className="col-11 ps-0 ">
                      <Form.Group className="p-0 " controlId="modalUserAddForm.DisplayName">
                        <Form.Control className="p-0 k-inputText k-inputText15" type="text" placeholder="Display Name" name='displayName'  onChange={handleInputChange}/>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row className="k-inputTextLarge">
                    <Col className="px-0 col-1"></Col>
                    <Col className="col-11 ps-0 ">
                      <Form.Group className="p-0 " controlId="modalUserAddForm.Email">
                        <Form.Control className="p-0 k-inputText k-inputText15" type="text" placeholder="Email" name='email'  onChange={handleInputChange}/>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="k-inputTextLarge">
                    <Col className="px-0 col-1"></Col>
                    <Col className="col-11 ps-0 ">
                      <Form.Group className="p-0 " controlId="modalUserAddForm.Phone">
                        <Form.Control className="p-0 k-inputText k-inputText15" type="text" placeholder="Phone" name='phone'  onChange={handleInputChange}/>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    {isDisplayNameValid ?  null: <small style={{color: 'red'}}>Enter a valid Display Name. {'(5 - 25 Alphanumeric only)'}</small>}
                    {isEmailValid ?  null: <small style={{color: 'red'}}>Enter a valid Email Name.</small>}
                    {isPhoneValid ?  null: <small style={{color: 'red'}}>Enter a Phone </small>}
                  </Row>
                </Col>
              </Row>
              
              <Row className="py-3 k-bb">
                <Row>
                  <Col className="px-0 col-1">
                    <img alt="name icon" src={viewportThree} className="k-modalIconSM pe-1 k-ctrlImg"/>
                  </Col>
                  <Col className="k-inputText18 col-11 ps-0">
                    Access Control
                  </Col>
                </Row>
                <Row className="pe-0">
                  <Col className="px-0 col-1"></Col>
                  <Col className="col-11 py-0 ">
                    <Row>
                      <Col>
                        <Row className="k-inputText15 pt-2">
                          Role
                        </Row>
                        <Row>
                          
                          <Col>
                            <Row>
                              <Form.Group className="p-0 " controlId="modalUserAddForm.Role">
                                <Form.Select aria-label="Select a role" className="k-inputSelect k-inputText18" name='role' defaultValue="" onChange={handleInputChange} >
                                  <option disabled value="" >Select Role</option>
                                  <option value="user" >User</option>
                                  <option value="administrator">Admin</option>
                                </Form.Select>
                              </Form.Group>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                      {/*
                      <Col>
                        <Row className="k-inputText15 pt-2">
                          Groups
                        </Row>
                        <Row>
                          
                          <Col>
                            <Row>
                              <Form.Group className="p-0 " controlId="modalUserAddForm.SecurityGroups">
                                <Form.Select aria-label="Select a groups" disabled className="k-inputSelect k-inputText18" name='groups' defaultValue="" onChange={handleInputChange} >
                                  <option disabled value="" >Select Groups</option>
                                </Form.Select>
                              </Form.Group>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                      */}
                    </Row>
                  </Col>
                </Row>
                <Row>
                  {isRoleValid ?  null: <small style={{color: 'red'}}>Select User's Role</small>}
                </Row>
              </Row>

              <Row className="py-3">
                <Row>
                  <Col className="px-0 col-1">
                    <img alt="name icon" src={viewportFour} className="k-modalIconSM pe-1 k-ctrlImg"/>
                  </Col>
                  <Col className="k-inputText18 col-11 ps-0">
                    Create new user
                  </Col>
                </Row>
                <Row className="pe-0">
                  <Col className="px-0 col-1"></Col>
                  <Col className="col-11 p-0 ">
                    <div className="pt-2">
                      <Button className="k-btn-submit-col txt-14 k-center-content k-h-30" variant="secondary" 
                        type="submit"
                        disabled={!isDisplayNameValid || !isRoleValid || !isEmailValid || submitClicked}
                        size="sm">
                        Make it so
                      </Button>
                    </div>
                  </Col>
                </Row>
                {submitError? (<>
                  <small style={{color: 'red'}}>{submitError}</small>
                </>): null}
              </Row>
            </Container>
          </Modal.Body>
        </Form>
      </Modal>
    </>
  );
}



/**
 * Group Add Modal
 *  - Renders Form to allow users to add new generic products
 * @param props 
 * @returns 
 */
export const GroupAddModal: React.FunctionComponent<any> = (props: any) => {
  const [modalFormBody, setModalFormBody] = useState<any>({});
  const [isGroupNameValid, setGroupNameValid] = useState(false);
  const [isGroupDescriptionValid, setGroupDescriptionValid] = useState(true);
  const [groupUsers, setGroupUsers] = useState<any>([]);
  const [submitClicked, setSubmitClicked] = useState(false);


  const [isFormValid, setFormValid] = useState(false);

  const { handleClose, show, handleModalFormSubmit, submitError, users } = props;

  
  useEffect(() => {
    setModalFormBody({});
    setGroupNameValid(false);
    setGroupDescriptionValid(true);
    setGroupUsers([]);
  }, [show])

  const handleInputChange = (event: any) => {
    if(event.target.name === 'name') {
      // event.target.value = event.target.value.split(" ").join("");
      // event.target.value = event.target.value.replace(" ", "");
      if (variables.DEFAULT_FORM_VALIDATION.GROUP.NAME.test(event.target.value)) {
        setGroupNameValid(true);
      } else {
        setGroupNameValid(false);
      }
    }

    if(event.target.name === 'description') {
      // event.target.value = event.target.value.split(" ").join("");
      // event.target.value = event.target.value.replace(" ", "");
      if (variables.DEFAULT_FORM_VALIDATION.GROUP.DESCRIPTION.test(event.target.value) || event.target.value === '') {
        setGroupDescriptionValid(true);
      } else {
        setGroupDescriptionValid(false);
      }
    }


    setModalFormBody({
      ...modalFormBody,
      [event.target.name]: event.target.value,
    });


    if (isGroupNameValid && isGroupDescriptionValid ){
      setFormValid(true)
    } else {
      setFormValid(false)
    }
  }


  const addGroupUser = (event: any, userId: any) => {
    event.preventDefault();

    setGroupUsers([
      ...groupUsers,
      userId
    ]);

  }

  const removeGroupUser = (event: any, userId: number) => {
    event.preventDefault();
      
    setGroupUsers([...groupUsers.filter((user: any) => user !== userId)]);
    
  }


  const validateForm = () => {
    return;
  }

  const handleFormSubmit = async(event: any) => {
    event.preventDefault();

    validateForm();

    if (isGroupNameValid && isGroupDescriptionValid){
      setSubmitClicked(true);
      await handleModalFormSubmit({body: {
        ...modalFormBody,
        users: [
          ...groupUsers
        ]
      }});
      setSubmitClicked(false);

    } else {
      helpers.logToOutput('FORM NOT VALID... Won\'t make it so 8');
    }
  }

  return (
    <>
      <Modal show={show} onHide={handleClose} contentClassName="k-mc-right" dialogClassName="kuro-modal" tabIndex={-1} size="lg" className="k-modal k-m-right" >
        <Form onSubmit={handleFormSubmit}>

          <Row className="k-modalHeader p-0 m-0">
            <img alt="name icon" src={viewportThree} className="k-modalTitleImg p-0 k-ctrlImg"/>
            <Row className="k-center ps-3">
              <Col className="col-2 p-0">
                <img alt="name icon" src={viewportFour} className="k-modalIcon pe-1 pt-2 k-ctrlImg"/>
              </Col>
              <Col className="col-10 ps-3 k-pt-2">
                <Row className="k-headerText">Create Group</Row>
                <Row className="k-subHeaderText">Regulate - Create - Manifest</Row>
              </Col>
            </Row>
          </Row>
          
          <Modal.Body className="k-modalBody">
            <Container className="pe-0">
              <Row className="">
                <Col className="col-12 pb-3 k-bb">
                  <Row>
                    <Col className="px-0 col-1">
                      <img alt="name icon" src={viewportFour} className="k-modalIconSM pe-1 k-ctrlImg"/>
                    </Col>
                    <Col className="k-inputText18 col-11 ps-0">
                        Enter a name and description
                    </Col>
                  </Row>
                  <Row className="k-inputTextLarge">
                    <Col className="px-0 col-1"></Col>
                    <Col className="col-11 ps-0 ">
                      <Form.Group className="p-0 " controlId="modalGroupAddForm.Name">
                        <Form.Control className="p-0 k-inputText k-inputText22" type="text" placeholder="Group Name" name='name'  onChange={handleInputChange}/>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="k-inputTextMedium">
                    <Col className="px-0 col-1"></Col>
                    <Col className="col-11 ps-0 ">
                      <Form.Group className="p-0 " controlId="modalGroupAddForm.Description">
                        <Form.Control className="p-0 k-inputText k-inputText15" type="text" placeholder="Group description"  name='description' onChange={handleInputChange} />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    {isGroupNameValid ?  null: <small style={{color: 'red'}}>Enter a valid Group Name. {'(5 - 25 Alphanumeric only)'}</small>}
                    {isGroupDescriptionValid ?  null : <small style={{color: 'red'}}>Enter a valid Group Description. {'(Alphabets, Numbers, space and charcaters[-., ()/] accepted)'}</small>}
                  </Row>
                </Col>
              </Row>
              
              <Row className="py-3 k-bb">
                <Row>
                  <Col className="px-0 col-1">
                    <img alt="name icon" src={viewportFour} className="k-modalIconSM pe-1 k-ctrlImg"/>
                  </Col>
                  <Col className="k-inputText18 col-11 ps-0">
                    Configure Group
                  </Col>
                </Row>
                <Row className="pe-0">
                  <Col className="px-0 col-1"></Col>
                  <Col className="col-11 py-0 ">
                    <Row>
                      <Col>
                        <Row className="k-inputText15">
                          Linked Projects
                        </Row>
                        <Row className="disabled-text">
                          ----
                        </Row>
                      </Col>
                      <Col >
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Row>

              
              <Row className="py-3 k-bb">
                <Row>
                  <Col className="px-0 col-1">
                    <img alt="name icon" src={viewportFour} className="k-modalIconSM pe-1 k-ctrlImg"/>
                  </Col>
                  <Col className="k-inputText18 col-11 ps-0">
                    Add Users
                  </Col>
                </Row>
                <Row className="pe-0">
                  {/* <Col className="px-0 col-1"></Col>
                  <Col className="col-11 py-0 ">
                    <Row>
                      <Col>
                        <Row className="k-inputText15 pt-2">
                          Type
                        </Row>
                        <Row>
                          
                          <Col>
                            <Row>
                              <Form.Select aria-label="Select a desktop" id="modalGroupAddForm.users" className="k-inputSelect k-inputText12" name='users' defaultValue="" onChange={handleInputChange} >
                                <option disabled value="" >Select </option>
                              </Form.Select>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col> */}

                  <Row>
                    <Col className="col-1"/>
                    <Col className="col-8">
                        Username
                    </Col>
                    <Col className="col-1"/>
                  </Row>

                  {groupUsers && groupUsers.map((user: any, index: number) => (
                    <Row key={`paneGroupEditForm.Users.${index}`}>
                      <Col className="col-1"/>
                      <Col className="col-4 k-inputText k-inputText15">
                        {user}
                      </Col>
                      <Col className="col-1">
                        <div style={{ cursor: 'pointer'}} onClick={(event: any) => removeGroupUser(event, user)}>
                          <span>
                            <img className="k-ctrlImg" src={viewportFour} alt={'Delete Icon'}/>
                          </span>
                        </div>
                      </Col>
                    </Row>))}
                  <Row>
                    <Col className="col-1"/>
                    <Col className="col-8 p-0 ">
                      <Row style={{ cursor: 'pointer'}}>
                        
                      </Row>

                      <Dropdown>
                        <Dropdown.Toggle as={utilComponents.AdminGroupAddUsersDropdownToggle}>
                          <Row>
                            <Col className="px-0 col-1">
                              <img alt="name icon" src={viewportFour} className="k-modalIconSM pe-1 k-ctrlImg"/>
                            </Col>
                            <Col className="col-11 p-0 ">
                            Add user
                            </Col>
                          </Row>
                        </Dropdown.Toggle>

                        <Dropdown.Menu as={utilComponents.AdminGroupAddUsersDropdownMenu} >
                          {users && users.map((user: any, index: number) => {
                            return (groupUsers && groupUsers.includes(user.id)) ?   
                              null :
                              (<Dropdown.Item key={`paneGroupEditForm.Dropdown.${index}`} onClick={(event: any) => addGroupUser(event, user.id)}>{user.id}</Dropdown.Item>)
                          })}
                        </Dropdown.Menu>
                      </Dropdown>
                    </Col>
                  </Row>

                  
                </Row>
              </Row>

              <Row className="py-3">
                <Row>
                  <Col className="px-0 col-1">
                    <img alt="name icon" src={viewportThree} className="k-modalIconSM pe-1 k-ctrlImg"/>
                  </Col>
                  <Col className="k-inputText18 col-11 ps-0">
                    Launch your group
                  </Col>
                </Row>
                <Row className="pe-0">
                  <Col className="px-0 col-1"></Col>
                  <Col className="col-11 p-0 ">
                    <div className="pt-2">
                      <Button variant="secondary" 
                        type="submit"
                        disabled={!isGroupNameValid || !isGroupDescriptionValid || submitClicked}
                        size="sm" className="k-btn">
                        Make it so
                      </Button>
                    </div>
                  </Col>
                </Row>
                {submitError? (<>
                  <small style={{color: 'red'}}>{submitError}</small>
                </>): null}
              </Row>
            </Container>
          </Modal.Body>
        </Form>
      </Modal>
    </>
  );
}


