import { Row, Col, Container, Nav, Navbar, Spinner, Button } from 'react-bootstrap';
import { useEffect, useState, useContext } from 'react';
import { Link, useLocation } from 'react-router-dom'
import { w3cwebsocket } from 'websocket';
// import { useUser } from '../../../hooks/auth';
import classNames from 'classnames';

// component
import Menu from './Menu';
import NotificationDropdown from './NotificationDropdown';
import ProfileDropdown from './ProfileDropdown';




import {
  businessLogoSimple
} from '../../../assets/images'

// utilities
import { 
  api, 
  authContextHelper, 
  types, 
  variables, 
  helpers 
} from 'utilities';

type TopNavProps = {
    isSticky?: boolean;
    navClass?: string;
    fixedWidth?: boolean;
    showTopSearchBar: boolean;
};

let socket = new w3cwebsocket(variables.DEFAULT_CONFIG ? variables.DEFAULT_CONFIG['WEB_SOCKET_ENDPOINT']: 'wss://socket.enterprise.kuro.click');

const getNotificationProductVariant = (productNewStatus: string): string => {
  let variant = 'primary';

  switch(productNewStatus.toLowerCase()){
  case 'running':
    variant = 'success';
    break;
        
  case 'stopped':
    variant = 'danger';
    break;
        
  default:
    variant = 'orange';
    break;
  }

  return variant;
}

// const getTimeAgo = (epochTime: number): string => {
//   // This function computes the delta between the
//   // provided timestamp and the current time, then test
//   // the delta for predefined ranges.

//   let d=new Date();  // Gets the current time
//   let nowTs = Math.floor(d.getTime()/1000); // getTime() returns milliseconds, and we need seconds, hence the Math.floor and division by 1000
//   let seconds = nowTs-epochTime;
//   let timeAgoToReturn: string = '';
//   // more that two days
//   if (seconds > 2*24*3600) {
//     timeAgoToReturn =  'a few days ago';
//   }
//   // a day
//   if (seconds > 24*3600) {
//     timeAgoToReturn = 'yesterday';
//   }

//   if (seconds > 3600) {
//     timeAgoToReturn = 'a few hours ago';
//   }
//   if (seconds > 1800) {
//     timeAgoToReturn = 'Half an hour ago';
//   }
//   if (seconds > 60) {
//     timeAgoToReturn = Math.floor(seconds/60) + ' minutes ago';
//   }

//   return timeAgoToReturn;
// }


const Topnav: React.FC<TopNavProps> = ({ isSticky, navClass, fixedWidth, showTopSearchBar }: TopNavProps) => {
  const authContext = useContext(authContextHelper.AuthContext);
  const isSignedIn = authContext.authStatus === authContextHelper.AuthStatus.SignedIn;
  const [notificationsSorted, setNotificationsSorted] = useState<any>([]);
  const [isNewNotifications, setIsNewNotifications] = useState<boolean>(true);
  const [userAttributes, setUserAttributes] = useState<types.IAccountUserAttributes | null>({
    ...authContext.userProfile['attributes'],
    role: authContext.userProfile['role']
  });

  const location = useLocation();

  socket.onopen = () => {
  };

  socket.onmessage = (message: any) => {

    const socketData = JSON.parse(message['data'])

    if(socketData){

      let newNotification; 

      if(socketData['type'] === 'product'){
        newNotification = {
          icon: 'message-square',
          variant: getNotificationProductVariant(socketData['data']['new']['state']),
          text: `${socketData['data']['new']['tags'].kname} : ${socketData['data']['new']['state'].toUpperCase()}`,
          //time: `${getTimeAgo(parseInt(socketData["time"]))}`
          time: helpers.getConditionalDateTime(socketData['time'])
        }
      }
            
      setNotificationsSorted([
        newNotification,
        ...notificationsSorted
      ]);

      setIsNewNotifications(true);
    }

  }

  const handleSignOut = async (event: React.MouseEvent) => {
    event.preventDefault();
    authContext.signOut();
  }



  // const logWebSocketEvents = (payload: any) => {

  //   // If Reasonable, Add to notifications queue
  //   // Fetch latest notifications
  //   // Set latest notifictaion first
  //   // Then spread rest
  // }

  // on scroll add navbar class
  useEffect(() => {
    const btnTop = document.getElementById('btn-back-to-top');

    window.addEventListener('scroll', (e) => {
      e.preventDefault();
      if (btnTop) {
        if (document.body.scrollTop >= 50 || document.documentElement.scrollTop >= 50) {
          btnTop.classList.add('show');
        } else {
          btnTop.classList.remove('show');
        }
      }
    });

    // Setup Websocket notifications listener
    // webSocket.socket.on("product", logWebSocketEvents);
    // webSocket.socket.on("", logWebSocketEvents);
    // webSocket.socket.on("NOTIFICATION_EVENT", logWebSocketEvents);

    

    attachWebSocket();

    // getUserAttributes();
    setUserAttributes({
      ...authContext.userProfile['attributes'],
      role: authContext.userProfile['role']
    })

        
  }, [authContext.userProfile]);

  const attachWebSocket = () => {
  }


  const clearNotifications = () => {
    setNotificationsSorted([])
  }


  const triggerWebSocketPayload = async (event: any) => {
    await fetch('/api/socket');  

  }


  return (
    <header>
      <Navbar
        id={isSticky ? 'sticky' : ''}
        collapseOnSelect
        expand="lg"
        className={classNames('topnav-menu navbar-sticky', navClass)}
      >
        <Container fluid={!fixedWidth}>
          {/*
                    <Navbar.Brand href="/" className="logo">
                        <img src={logo} height="30" className="align-top logo-dark" alt="" />
                        <img src={logoLight} height="30" className="align-top logo-light" alt="" />
                    </Navbar.Brand>
                    */}

          <Navbar.Toggle className="me-3" aria-controls="topnav-menu-content4" />

          <Navbar.Collapse id="topnav-menu-content4">
            <Menu />
                        
            <Nav as="ul" className="navbar-sections align-items-lg-center">
              <div className='pt-2 ps-2'> {/* This is the left section */}
                {/*<img className='k-mh-30' src={businessLogo}></img>*/}
                {
                  showTopSearchBar ? (
                    location.pathname !== '/' ? (
                      <Link to="/">
                        <img className='ms-2 k-mh-40' src={businessLogoSimple}></img>
                      </Link>
                    ) : (
                      <></>
                    )
                  ) : (
                    <></>
                  )
                }
              </div> 
              <div className="nav-item"> {/* This is the middle section */}
                            
                                
              </div>
              { isSignedIn ?   
                <div className="align-items-lg-center"> {/* This is the right section */}
                  <Row className='m-0 p-0'> 
                    <Col className='m-1 p-0'>
                      {/*<Button onClick={triggerWebSocketPayload}>Sock-Trig</Button>*/}
                    </Col>
                    
                    <Col className='m-0 p-0'>
                      <NotificationDropdown 
                        notifications={notificationsSorted} 
                        isNewNotifications={isNewNotifications}
                        setIsNewNotifications={setIsNewNotifications}
                        clearNotifications={clearNotifications}
                      />
                    </Col>
                    <Col className='m-0 p-0'>
                      {userAttributes &&
                        <ProfileDropdown 
                          userAttributes={userAttributes} 
                          handleSignOut={handleSignOut}
                        />}
                    </Col>

                  </Row>
                </div>
                :
                <></>
              }
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
};

export default Topnav;
