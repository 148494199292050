import { useDroppable } from '@dnd-kit/core';
import { FC, useEffect, useState } from 'react';

import { helpers, types } from 'utilities'

interface IViewport {
  vpProductIndex: number;
  products: any;
  viewportProducts: types.ViewportProducts[];
}

const handleIframeMouseOver = async(event: any) => {
  event.preventDefault();
  const iframeDocument = document.getElementById(event.target.id);
  iframeDocument?.focus();
}

interface ViewportIndexRunningStates {
  1: boolean,
  2: boolean,
  3: boolean,
  4: boolean,
}

const Viewport: FC<IViewport> = ({ vpProductIndex, products, viewportProducts}) => {
  const [isViewPortsRunning, setViewPortsRunning] = useState <boolean[]>([false, false, false, false, false]);
  const { setNodeRef } = useDroppable({
    id: `cartdroppable-${vpProductIndex}`
  });

  useEffect(() => {
    if (viewportProducts[vpProductIndex].id) {
      setViewPortsRunning(cvpr => {
        // helpers.logToOutput('use effect 1', cvpr);
        let updatedCVPR = cvpr;
        let curr_product = products && products.find((p: any) => viewportProducts[vpProductIndex].id === p.id )
        updatedCVPR[vpProductIndex] = curr_product['state'] === 'running';

        return updatedCVPR;
      })
    }
  }, [products])

  //<ul className={styles.cart} ref={setNodeRef}>
  {/*props.items.map((item, idx) => (
        <div key={`${item}-${idx}`} className={styles["cart-item"]}>
          {item}
        </div>
      ))*/}

  const fetchDataWithToken = async (): Promise<void> => {
    try {
      helpers.logToOutput('Flash props.src[props.vp] has changed:', vpProductIndex);
          
      const token = JSON.parse(localStorage.getItem('GUAC_AUTH'));

      if (!token) {
        helpers.logToOutput('GUAC_AUTH token not found in localStorage');
        return;
      }
    
      const url = `${vpProductIndex}/api/session/data/mysql/activeConnections`;
    
      const data = await fetchActiveConnection(url, 'GET', 'application/json', token.authToken);
      helpers.logToOutput('Flash ! ActiveConnections: ', data);
      try {
        const responseObject = JSON.parse(data);
        const keys = Object.keys(responseObject);
        helpers.logToOutput('Flash Keys : ', keys)
        keys.forEach(key => {
          const identifier = responseObject[key].identifier;
          helpers.logToOutput(`Flash Identifier value for key ${key}:`, identifier);
          //removeConnection(url, 'PATCH', 'application/json', token.authToken, '/'+identifier );
        });
      } catch (error) {
        helpers.logToOutput('Error parsing JSON:', error);
      }

    
    } catch (error) {
      helpers.logToOutput('Flash ActiveConnections ERROR: ', error);
      // Handle errors
    }
  };

  useEffect(() => {
    fetchDataWithToken();
  }, [vpProductIndex]);

  return (
    <div id={`cartdroppable-${vpProductIndex}`} className={'cart k-iFrameHolder'} ref={setNodeRef}>
      { isViewPortsRunning[vpProductIndex] ? 
        <iframe className="k-iFrame d-flex align-self-stretch" 
          allow="clipboard-read; clipboard-write;camera; microphone; web-share; display-capture;" id={`desktop-iframe-vp-${vpProductIndex}`} 
          src={viewportProducts[vpProductIndex].url} title="BOB"
          onMouseOver={handleIframeMouseOver}>
        </iframe>
        : 
        null
      }
    </div>
  );
};


async function fetchActiveConnection(url: string, method: string, contentType: string, token: any): Promise<string> {
  try {
    const response = await fetch(url, {
      method: method,
      headers: {
        'Content-Type': contentType,
        'Guacamole-Token': token
      }
    });

    if (!response.ok) {
      throw new Error('Flash Network response was not ok');
    }

    const data = await response.text(); // Change to response.json() if expecting JSON data
    helpers.logToOutput('flash Response from server:', data);
    return data;
  } catch (error) {
    helpers.logToOutput('Flash There was an error with the request:', error);
    throw error; // Rethrow the error to be caught by the caller
  }
}

async function removeConnection(url: string, method: string, contentType: string, token: any, path: string): Promise<string> {
  try {
    const response = await fetch(url, {
      method: method,
      headers: {
        'Content-Type': contentType,
        'Guacamole-Token': token
      },
      body: JSON.stringify([{
        'op': 'remove',
        'path': path
      }]),
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.text(); // Change to response.json() if expecting JSON data
    helpers.logToOutput('Response from server:', data);
    return data;
  } catch (error) {
    helpers.logToOutput('There was an error with the request:', error);
    throw error; // Rethrow the error to be caught by the caller
  }
}

export default Viewport;