import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';


const Footermain: React.FC = () => {
  return (
    <section className="section pt-1 pb-1 position-relative">
      <Container>
        <Row className="align-items-center">
          <Col className="text-center">
            <ul className="list-inline list-with-separator">
              {/*<li className="list-inline-item me-0 fs-12">
                <Link to="#">About</Link>
              </li>
              <li className="list-inline-item me-0 fs-12">
                <Link to="#">Support</Link>
              </li>
              */}
              <li className="list-inline-item me-0 fs-12">
                                Version{' '}
                <Link to="#">
                  <span className="align-middle badge badge-soft-info rounded-pill px-2 py-1 fs-12">
                                        v1.1.2
                  </span>
                </Link>
              </li>
            </ul>
            {/* 
              <p className="fs-12">
                  {new Date().getFullYear()} © Kuro. All rights reserved. 
              </p>

              <img src={logo} height="30" className="mt-2 mb-4" alt="" />
            */}
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Footermain;
